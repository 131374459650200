import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "./seo"
import poster from "../../static/poster.png"
const Home = () => {
  return (
    <>
      <Seo title="Vahva kahvi vahvalle kansalle" image={poster} />
      <p>
        Suomalaiset ovat vahvaa kansaa, jotka kaiken lisäksi juovat eniten
        kahvia maailmassa. Siksi ei ole sama minkälaista kahvia suomalainen
        kaataa kurkkuunsa. <br />
        <br />
        Löfbergs on sitä mieltä, että vahva kansa ansaitsee arvoisensa kahvin –
        vaikka se olisikin ruotsalaista. Testaa mikä on oma vahvuutesi ja katso
        samalla mikä kahveistamme voisi sopia sinun pirtaasi.
        <br />
        <br />
        <br />
      </p>
      <h2>Testaa vahvuutesi.</h2>
      <p>
        Vastaa seuraaviin kysymyksiin ja löydä vahvuutesi. Samalla saat selville
        mikä Löfbergsin kahveista sopii parhaiten juuri sinun makuusi ja
        luonteeseesi.
        <br /> <br />
        {/* Muista myös jättää yhteystietosi ja osallistua arvontaan! Voit voittaa
        komean ja kestävän Stanley‑eväslaatikon ja ‑termospullon sekä kahvit
        koko kevääksi. Palkinnon arvo on yhteensä n. 205 €.
        <br />
        <br /> */}
      </p>
      <Link className="button" to="/testi#q">
        Aloita testi
      </Link>
      {/* <StaticImage
        src="../assets/palkinto.png"
        alt="Osallistu arvontaan"
        className="palkinto"
      /> */}

      {/* <div className="contest">
        <h3>
          Osallistu samalla arvontaan, voit voittaa Löfbergs-kahvimukin ja
          vuoden kahvit!
        </h3>
        <StaticImage src="../assets/mukit.png" alt="Osallistu arvontaan" />
      </div> */}
    </>
  )
}

export default Home
